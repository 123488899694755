(function(){
     'use strict';

     angular
        .module('app')
        .factory('InclinicService', InclinicService);

    InclinicService.$inject = ['$http'];

    function InclinicService($http){

        var service= {
           getWifiRasp:getWifiRasp,
           setWifiRasp:setWifiRasp
        };

        return service;

        function getWifiRasp(rasp_id,callback)
        {
            var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                }
            }
            $http.get('/uranolab/getwifirasp', { params : { rasp_id:rasp_id, lang_id:lang_id } }, config)
            .then(getContentSuccess,getContentFailed);
            function getContentSuccess(data){ 
                callback(data.data);
            }
            function getContentFailed(data){
                
                return false;
            }
        }
        function setWifiRasp(rasp_id,wifiinfo,callback)
        {
        	var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                }
            }
            var data = $.param({
               wifiinfo:JSON.stringify(wifiinfo),
               rasp_id:rasp_id,
               lang_id:lang_id
            });
            $http.post('/uranolab/setwifirasp', data, config)
            .then(getContentSuccess,getContentFailed);
            function getContentSuccess(data){ 
                callback(data.data);
            }
            function getContentFailed(data){
                return false;
            }
        }

    }
})();